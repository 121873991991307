<template>
  <div :id="`${component.name}Markers`" class="map-markers">
    <div class="container">
      <div class="subject">{{ computedRegion.title }}</div>
      <div class="desc">{{ computedRegion.desc }}</div>
      <div :id="`${component.name}Map`" class="map" :style="{ height: computedRegion.height ? computedRegion.height + 'px' : false }"></div>
      <div class="projects" v-if="computedRegion.type === 'WITH_PROJECTS'">
        <div class="title">진행 중인 프로젝트</div>
        <ul class="tight clearfix" v-if="state.projects.length">
          <li v-for="(p, idx) in state.projects" :key="idx + 1"> <!-- idx + 1 하는 이유 : 하단의 스켈레톤 UI와 키를 맞추기 위해(progress-bar 애니메이션 효과 가능)-->
            <Card
                :projectSeq="p.projectSeq"
                :projectType="p.projectType"
                :link="`/reward/${p.projectSeq}`"
                :thumbFilePath="p.thumbFilePath"
                :amount="p.progressOrder === 1 ? p.projectType === 'reward' ? p.expenseAmt : p.expenseAmt : p.expenseAmt"
                :projectName="p.projectName"
                :progressOrder="p.progressOrder"
                :simpleText="p.projectType === 'invest' ? projectType === 'search' ? '' : undefined : p.simpleText"
                :projectCate="p.projectCateName"
                :fundingType="p.fundingType"
                :count="p.investorCount"
                :percent="p.investRate"
                :dday="Number(p.dday)"
                :builderName="p.builderName"
                :builderImageUrl="p.builderImageUrl"
                :builderSeq="p.builderSeq"
                :successFailName="p.successFailName"
                :stockTypeText="p.stockTypeText"
                :rewardFlag="p.rewardFlag"
                :incomeDeductionFlag="p.incomeDeductionFlag"
                :interest="true"
                :privateEquityApprovedCnt="p.privateEquityApprovedCnt"
                :privateEquityParticipantStatusCode="p.privateEquityParticipantStatusCode"
                :stateHide="p.projectStateHideYn === 'Y'"
                :mainImagePosition="p.mainImagePosition"
            />
          </li>
        </ul>
        <ul class="tight clearfix" v-else>
          <li v-for="i in 20" :key="i">
            <Card :skeleton="true" builderName="wait a moment" projectName="wait" projectType="reward" projectCate="wait"
                  simpleText="Please wait a moment" fundingType="A" :progressOrder="1" :count="0" :amount="0" :percent="0"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, onMounted, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import definitions from "@/scripts/definitions";
import store from "@/scripts/store";
import router from "@/scripts/router";
import http from "@/scripts/http";
import Card from "@/components/Card";

function Component(initialize) {
  this.name = "pageMapMarkers";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Card},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      computedRegion.value.type === "WITH_PROJECTS" && http.get("/api/reward/projects?projectType=reward&pageIndex=1&pageUnit=20").then(({data}) => {
        state.projects = data.body.list;
      });
    });

    const state = reactive({
      projects: [],
      drawIdx: 0,
      timeout: 0,
      interval: 0,
    });

    const regions = {
      gyeongnam: {
        title: "경상국립대 창업중심대학사업단",
        desc: "기업의 로고를 클릭하시면 상세 정보를 조회하실 수 있습니다.",
        type: "WITH_PROJECTS",
        height: 365,
        centerPositions: [35.24516214487667, 128.4464671636548],
        zoomLevel: 11,
        enterprises: [
          {
            title: "진주전자",
            address: "경상남도 진주시 향교로109번길 52-5",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam1.svg",
            positions: [35.19835970045314, 128.09730833387567]
          },
          {
            title: "진주산업연구소",
            address: "경상남도 진주시 도동로 57",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam2.svg",
            positions: [35.174410737336615, 128.12205779771963]
          },
          {
            title: "진주식품",
            address: "경남 진주시 가좌동 2033",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam3.svg",
            positions: [35.14912556967435, 128.1117026710116]
          },
          {
            title: "의령농촌복지조합",
            address: "경남 의령군 의령읍 서동리 261",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam4.svg",
            positions: [35.32272935561713, 128.25386664394946]
          },
          {
            title: "그린함안바이오",
            address: "경남 함안군 함안면 북촌리 산 21-3",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam5.svg",
            positions: [35.24516214487667, 128.4464671636548]
          },
          {
            title: "창원어부조합",
            address: "경상남도 창원시 성산구 두산볼보로 22",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam6.svg",
            positions: [35.17705339749467, 128.59919673880805]
          },
          {
            title: "물류재선별창원센터",
            address: "경남 창원시 진해구 청안동 산 25-4",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam7.svg",
            positions: [35.107129632472194, 128.7940069411612]
          },
          {
            title: "김해신재생에너지연구센터",
            address: "경남 김해시 안동 산 6",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam8.svg",
            positions: [35.2420127780952, 128.92140951624344]
          },
          {
            title: "경남김해산업진흥원",
            address: "경남 김해시 삼계동 1520-3",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam9.svg",
            positions: [35.263158496937585, 128.87171738683332]
          },
          {
            title: "고성공룡화석발굴조사원",
            address: "경남 고성군 고성읍 대독리 산 74-3",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam10.svg",
            positions: [34.96607153650776, 128.30350240950975]
          },
          {
            title: "사천통합전기전자",
            address: "경남 사천시 백천동 산 60-1",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam11.svg",
            positions: [34.98986956957419, 128.08711472955366]
          },
          {
            title: "사천건설",
            address: "경남 사천시 서포면 자혜리 356-6",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam12.svg",
            positions: [35.00408822492099, 128.01474197370587]
          },
          {
            title: "꽃피는산청플라워숍",
            address: "경상남도 산청군 산청읍 꽃봉산로 102",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam13.svg",
            positions: [35.412204064568265, 127.87591891850931]
          },
          {
            title: "합천종합스포츠몰",
            address: "경남 합천군 합천읍 합천리 1364-173",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam14.svg",
            positions: [35.559858586263914, 128.15908124372734]
          },
          {
            title: "하동스마트교복",
            address: "경남 하동군 하동읍 광평리 12-1",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam15.svg",
            positions: [35.06178465382611, 127.75061066494031]
          },
          {
            title: "거제물류운송",
            address: "경남 거제시 고현동 759-29",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam16.svg",
            positions: [34.88576312337194, 128.6198911263169]
          },
          {
            title: "거제종합버스운송조합",
            address: "경상남도 거제시 옥포대첩로4길 28",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam17.svg",
            positions: [34.891491594245274, 128.69289646020528]
          },
          {
            title: "통영관광도시사업소",
            address: "경상남도 통영시 데메4길 6",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam18.svg",
            positions: [34.82937188528826, 128.423045485133]
          },
          {
            title: "거창실매물중고차거래소",
            address: "경상남도 거창군 거창읍 상동8길 12",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam19.svg",
            positions: [35.68573588762434, 127.90552198958922]
          },
          {
            title: "밀양식품전문운송사",
            address: "경남 밀양시 삼문동 398-6",
            logo: "/assets/ico/map/page.map.markers.marker.gyeongnam20.svg",
            positions: [35.49128162533384, 128.74538631494892]
          },
        ]
      },
      incheon: {
        title: "인천 서구 사회적경제 Vision-On",
        desc: "기업의 로고를 클릭하시면 상세 정보를 조회하실 수 있습니다.",
        type: "ONLY_MAP",
        height: null,
        centerPositions: [37.54423875384124, 126.6765060645705],
        zoomLevel: 8,
        enterprises: [
          {
            title: "유니디자인",
            address: "인천광역시 부평구 부평대로 337, 부평제이타워 3차 1101호",
            linkUrl: "http://www.unidesign.kr/",
            logo: "/assets/ico/map/page.map.markers.marker.incheon1.svg",
            positions: [37.52202356752441, 126.72112417077115]
          }, {
            title: "더원아트코리아",
            address: "인천광역시 서구 서달로 179번길 12-2, 2층",
            linkUrl: "http://www.theoneartkorea.com/",
            logo: "/assets/ico/map/page.map.markers.marker.incheon2.svg",
            positions: [37.51488574548995, 126.67787986285282]
          }, {
            title: "파라서",
            address: "인천광역시 서구 거북로119번길 8-1",
            linkUrl: "http://paraseo.co.kr/",
            logo: "/assets/ico/map/page.map.markers.marker.incheon3.svg",
            positions: [37.503812505254274, 126.67543370109166]
          }, {
            title: "하늘샘배움터",
            address: "인천시 서구 탁옥로 97번길 16-6",
            linkUrl: "http://skysaem.or.kr/",
            logo: "/assets/ico/map/page.map.markers.marker.incheon4.svg",
            positions: [37.544215218348, 126.6811031253193]
          }, {
            title: "예일종합목재",
            address: "인천광역시 서구 북항로178번길 4",
            linkUrl: "http://www.yiwood.co.kr/",
            logo: "/assets/ico/map/page.map.markers.marker.incheon5.svg",
            positions: [37.507199605922736, 126.62928557292625]
          }, {
            title: "한국근로장애인진흥회",
            address: "인천 서구 당하동 원당대로 608 번안길 15",
            linkUrl: "http://kawd.org/",
            logo: "/assets/ico/map/page.map.markers.marker.incheon6.svg",
            positions: [37.59120927986251, 126.66663328070555]
          }, {
            title: "클린앤텍",
            address: "인천광역시 서구 보듬로 158 318호",
            linkUrl: "http://cleanntech.co.kr/",
            logo: "/assets/ico/map/page.map.markers.marker.incheon7.svg",
            positions: [37.59603180255813, 126.61552048652474]
          }, {
            title: "씨케이크린앤환경",
            address: "인천광역시 서구 검단로 516, 308호",
            linkUrl: "http://청소와소독.com",
            logo: "/assets/ico/map/page.map.markers.marker.incheon8.svg",
            positions: [37.60319751362112, 126.6613053984105]
          }, {
            title: "사단법인 한국복지나눔",
            address: "인천광역시 서구 서곶로315번길 15 1-화성빌딩 304호",
            linkUrl: "http://k-welfare.kr/",
            logo: "/assets/ico/map/page.map.markers.marker.svg",
            positions: [37.54556547585074, 126.67486802819813]
          }, {
            title: "천상의커피",
            address: "인천광역시 서구 원당대로840번길 5 장원프라자 410",
            linkUrl: "https://blog.naver.com/cafedelcielo2",
            logo: "/assets/ico/map/page.map.markers.marker.svg",
            positions: [37.59301329993881, 126.69487039924768]
          }, {
            title: "코워킹소사이어티",
            address: "인천 서구 염곡로464번길 15 쓰리엠타워 801호",
            linkUrl: "http://www.worksmart.kr/",
            logo: "/assets/ico/map/page.map.markers.marker.incheon10.svg",
            positions: [37.526307385829014, 126.67105999614407]
          }, {
            title: "가까이한마음사회서비스센터",
            address: "인천시 서구 길주로 63번길 25.3층",
            linkUrl: "http://icjahwal1.cafe24.com/?p=836",
            logo: "/assets/ico/map/page.map.markers.marker.svg",
            positions: [37.508934639034486, 126.66891943442556]
          }, {
            title: "우리동네목공방 협동조합",
            address: "인천 서구 석남로 66-1",
            linkUrl: "",
            logo: "/assets/ico/map/page.map.markers.marker.svg",
            positions: [37.500830445195156, 126.66903155710149]
          }]
      }
    };

    const computedRegion = computed(() => {
      return regions[router.app.$route.params.name] || regions.incheon;
    });

    const getEnterprises = () => {
      return computedRegion.value.enterprises;
    };

    onMounted(() => {
      store.dispatch("appendScript", {
        src: `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${definitions.sns.properties.kakaoAppKeyJavaScript}&autoload=false`,
        onEveryLoad() {
          const kakao = window.kakao;

          kakao.maps.load(() => {
            const enterprises = getEnterprises();
            const mapContainer = document.getElementById(`${component.name}Map`);
            const mapOption = {
              center: new kakao.maps.LatLng(computedRegion.value.centerPositions[0], computedRegion.value.centerPositions[1]),
              level: computedRegion.value.zoomLevel
            };

            const map = new kakao.maps.Map(mapContainer, mapOption);
            const zoomControl = new kakao.maps.ZoomControl();

            map.setZoomable(false);
            map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);

            clearTimeout(state.timeout);
            clearInterval(state.interval);

            const moveMarker = () => {
              const $markers = document.querySelectorAll(`#${component.name}Markers img.marker:not(.moved)`);

              for (let i = 0; i < $markers.length; i += 1) {
                $markers[i].classList.add("moved");

                setTimeout(() => {
                  $markers[i].style.top = "0";
                });
              }
            };

            const overlays = [];

            state.timeout = setTimeout(() => {
              state.interval = setInterval(() => {
                const enterprise = enterprises[state.drawIdx];
                moveMarker();

                const imageSize = new kakao.maps.Size(42, 60);
                const markerImage = new kakao.maps.MarkerImage(enterprise.logo, imageSize);
                const position = new kakao.maps.LatLng(enterprise.positions[0], enterprise.positions[1]);

                const marker = new kakao.maps.Marker({
                  map,
                  position,
                  title: enterprise.title,
                  image: markerImage
                });

                const $marker = document.querySelector(`#${component.name}Markers img:not(.marker):not(.moved)[src^="/assets/ico/map/page.map.markers.marker"]`);

                if ($marker) {
                  $marker.style.transition = "top 0.5s";
                  $marker.style.top = "-100vh";
                  $marker.classList.add("marker");
                }

                kakao.maps.event.addListener(marker, "click", function () {
                  // 해당 위치로 이동
                  map.panTo(position);

                  // 오버레이 지우기
                  overlays.forEach(o => o.setMap(null));

                  // 오버레이
                  let overlay = overlays.find(o => o.title === enterprise.title);

                  if (!overlay) {
                    const content = [`<div class="overlay">`
                      , `   <div class="title">`
                      , `      <i class="fa fa-building"></i>&nbsp;`
                      , `      <b>${enterprise.title}</b>`
                      , `      <span class="remove" title="닫기">&times;</span>`
                      , `   </div>`
                      , `   <div class="desc">`
                      , `      <div class="address">${enterprise.address}</div>`
                      , `      <a class="color-anchor">${computedRegion.value.type === "WITH_PROJECTS" ? "프로젝트로 이동" : "자세히 보기"} </a>`
                      , `   </div>`
                      , `</div>`].join("");

                    overlay = new kakao.maps.CustomOverlay({
                      position,
                      content,
                      xAnchor: 0.5,
                      yAnchor: 1.4
                    });

                    // 오버레이 추가
                    overlay.title = enterprise.title;
                    overlays.push(overlay);
                  }

                  // 오버레이 입력
                  overlay.setMap(map);

                  const $overlayParent = document.querySelector(`#${component.name}Markers .overlay`)?.parentElement;

                  if ($overlayParent) {
                    $overlayParent.style.zIndex = "1";

                    const onAnchorClick = () => {
                      if (computedRegion.value.type === "WITH_PROJECTS") {
                        const project = state.projects[enterprises.indexOf(enterprise)];
                        location.href = `/reward/${project.projectSeq}`;
                      } else {
                        store.commit("openModal", {
                          name: "MapMarkerDetail",
                          params: {
                            title: enterprise.title
                          }
                        });
                      }
                    };

                    $overlayParent.querySelector(".desc > a").addEventListener("click", onAnchorClick);

                    $overlayParent.querySelector(".title .remove").addEventListener("click", () => {
                      overlay.setMap(null);
                    });
                  }
                });

                if (++state.drawIdx === enterprises.length) {
                  clearInterval(state.interval);
                  moveMarker();
                }
              }, 150);
            }, 1000);
          });
        }
      });
    });

    return {component, state, computedRegion};
  }
});
</script>

<style lang="scss" scoped>
.map-markers {
  padding: $px35 0;

  > .container {
    > .subject {
      font-size: $px24;
      font-weight: 600;
    }

    > .desc {
      padding-top: $px10;
      font-size: $px14;
    }

    .map {
      margin-top: $px30;
      height: calc(100vh - $px300);

      &::v-deep {
        .overlay {
          border-radius: 6px;
          margin-bottom: 12px;
          float: left;
          position: relative;
          background-color: #fff;
          cursor: default;

          > .title {
            display: block;
            background: #c30d23;
            text-decoration: none;
            color: #fff;
            padding: 12px 36px 12px 14px;
            font-size: 14px;
            border-radius: 6px 6px 0 0;
            position: relative;
            cursor: pointer;

            .remove {
              color: #fff;
              opacity: 0.76;
              position: absolute;
              cursor: pointer;
              top: 1px;
              right: 0;
              transform: translateY(50%);
              font-size: 22px;
              line-height: 0;
              padding: 10px;

              &:hover {
                opacity: 1;
              }
            }
          }

          .desc {
            padding: 14px;
            position: relative;
            width: 218px;
            border: 1px solid #eee;
            border-top-width: 0;

            .address {
              font-size: 12px;
              color: #333;
              line-height: 1.6;
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            > a {
              cursor: pointer;
              font-size: 12px;
            }
          }

          &:after {
            content: "";
            position: absolute;
            margin-left: -11px;
            left: 50%;
            bottom: -11px;
            width: 25px;
            height: 12px;
            background-image: url(/assets/ico/common.triangle.isosceles.svg?2);
            background-size: 100% 100%;
          }
        }
      }
    }

    .projects {
      padding-top: $px30;

      .title {
        font-size: $px18;
        font-weight: bold;
        margin-bottom: $px5;
      }

      ul {
        margin: 0 $px-10;

        li {
          float: left;
          width: calc(100% / 4 - 0.1px);
          padding: $px10 $px10 $px30 $px10;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .container {
      .projects ul li {
        width: 50%;
        padding-bottom: $px5;
      }
    }
  }
}
</style>